<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="900"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>计划名称：</p>
          <Input v-model="plan"
                 placeholder="计划名称"
                 maxlength="30"
                 v-stringLimit />
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>网格名称：</p>
          <MyTreeSelect :value="gridName"
                        placeholder="网格名称"
                        :data="gridNameTreeArr"
                        search
                        style="width:100%;"
                        @onSelectChange="onChangeGridName">
          </MyTreeSelect>
        </li> -->
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departmentName"
                        placeholder="所属机构"
                        clearable
                        :data="departmentTreeArr"
                        search
                        style="width:100%"
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业车辆：</p>
          <MyTreeSelect :value="car"
                        placeholder="作业车辆"
                        :disabled='!departmentId'
                        :data="carTreeArr"
                        search
                        style="width:100%;"
                        @onSelectChange="onChangeCar">
          </MyTreeSelect>
          <!-- <Input v-model="driver"
                 placeholder="驾驶员"
                 readonly /> -->
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业班组：</p>
          <MyTreeSelect :value="group"
                        placeholder="作业班组"
                        :data="groupTreeArr"
                        search
                        style="width:100%;"
                        @onSelectChange="onChangeGroup">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">作业规则：</p>
          <Select v-model="ruleId"
                  label-in-value
                  @on-change="onChangeRule"
                  clearable>
            <Option v-for="item in ruleArr"
                    :value="item.workRuleId"
                    :key="item.workRuleId">{{ item.ruleName }}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>有效时段：</p>
          <DatePicker type="date"
                      class="half"
                      style="margin-right:0"
                      :value="startTime"
                      :options="dateOptions"
                      :disabled='startDateDisabled'
                      :editable=false
                      @on-change="onChangeStart"
                      placeholder=""></DatePicker>
          <span style="margin: 0 10px;line-height:32px;">~</span>
          <DatePicker type="date"
                      class="half"
                      style="margin-right:0"
                      :value="endTime"
                      :options="dateOptions"
                      :disabled='endDateDisabled'
                      :editable=false
                      @on-change="onChangeEnd"
                      placeholder=""></DatePicker>
        </li>
        <li class="form-ul-li half ">
          <p class="li-title">中转站：</p>
          <MyTreeSelect :value="gather"
                        placeholder="中转站"
                        :data="gatherTreeArr"
                        search
                        checked
                        clearable
                        style="width:100%;"
                        @onCheckChange="onChangeGather">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half ">
          <p class="li-title">处理站：</p>
          <MyTreeSelect :value="dispose"
                        placeholder="处理站"
                        :data="disposeTreeArr"
                        search
                        checked
                        clearable
                        style="width:100%;"
                        @onCheckChange="onChangeDispose">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>预计行驶里程：</p>
          <Input v-model="expectMileage"
                 placeholder="预计行驶里程"
                 maxlength="30"
                 v-stringLimit />
        </li>
        <li class="form-ul-li half">
        </li>
        <li class="form-ul-li half">
          <p class="li-title"></p>
          <Icon custom="i-icon icon-jingshi"
                size="18"
                color="#FF9B77"
                class="m-r-5"></Icon>
          近七日平均行驶里程为：{{mileage}}km
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading='loading'
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { getTomorrow, formatDate } from '@/utils/dateRangUtil'
import { mapActions, mapGetters } from 'vuex';
import { getNearlySevenDay } from '@/utils/dateRangUtil'
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '排班'
    },
    dataId: String
  },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          // return date && date.valueOf() < Date.now()
          return false
        }
      },
      startDateDisabled: false,
      endDateDisabled: false,
      loading: false,
      plan: '', // 计划名称
      gridName: '', // 网格名称
      gridNameId: '', // 网格名称id
      car: '',   // 车辆名称
      carId: '',  // 车辆名称id
      driver: '',  // 驾驶员
      driverId: '', // 驾驶员id
      group: '',  // 班组
      groupId: '',  // 班组id
      rule: '', // 作业规则
      ruleId: '', // 作业规则
      startTime: '2021-01-01',  // 开始时间
      endTime: '2021-12-31',  // 结束时间
      gather: '', // 中转/收集站
      gatherId: '', // 中转/收集站id
      dispose: '', // 处理站
      disposeId: '', // 处理站id
      expectMileage: '',
      remark: '', // 备注
      // gridNameTreeArr: [],
      // 车辆树列表
      departmentName: '',
      departmentId: '',
      carTreeArr: [],
      driverTreeArr: [],
      groupTreeArr: [],
      gatherTreeArr: [],
      disposeTreeArr: [],
      ruleArr: [],
      nearlySevenDay: [],
      mileage: 0,
    }
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    },
    visible (newValue) {
      if (newValue) {
        this.getGroupList()
        this.getRuleList()
        this.getFacilityList()
      }
    }
  },
  computed: {
    ...mapGetters({
      'gridNameTreeArr': 'getGridTreeList',
      departmentTreeArr: 'getDepartmentTreeList',
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateGridTreeList',
      'updateDepartmentTreeList',
    ]),
    init () {
      this.updateGridTreeList()
      this.updateDepartmentTreeList()
      this.timeInit()
      this.getNearlySevenDay()
    },
    // 近七日
    getNearlySevenDay () {
      this.nearlySevenDay = getNearlySevenDay(false)
    },
    getDetail () {
      this.$store.dispatch('getMechanicalCleanPlanDetail', {
        carPlanId: this.dataId,
        type: 3
      }).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
        this.carId = res.carId.join()
        this.car = res.car.join()
        this.getSevenMileage()
        if (res.gatherId && res.gatherId.length > 0) {
          this.gatherId = res.gatherId.join()
          this.gather = res.gatherName.join()
        }
        if (res.disposeId && res.disposeId.length > 0) {
          this.disposeId = res.disposeId.join()
          this.dispose = res.disposeName.join()
        }
        if (!this.isBeyondDate(this.startTime)) {
          this.startDateDisabled = true
        }
        if (!this.isBeyondDate(this.endTime)) {
          this.endDateDisabled = true
        }
        this.getCarTreeList()
      })
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.carId = ''
      this.car = ''
      this.driverId = ''
      this.driver = ''
      this.departmentId = section.id
      this.departmentName = section.name
      // 获取车辆列表
      this.getCarTreeList()
    },
    // 获取车辆列表
    getCarTreeList (value) {
      let params = {
        carcategory: '83b292821074d284bcf443ad3b0ba92d',
        depid: this.departmentId
      }
      this.$http.getCarDetailTreeList(params).then(res => {
        if (res.code === 200) {
          this.carTreeArr = res.result
        }
      })
    },
    // 日期选择器赋初始值
    timeInit () {
      let time = new Date()
      // this.startTime = time.getFullYear() + '-01-01'
      this.startTime = getTomorrow()
      this.endTime = time.getFullYear() + '-12-31'
    },
    // 是否超出日期
    isBeyondDate (date) {
      return formatDate(new Date()) < date
    },
    // 收集点列表
    getFacilityList () {
      this.$http.getFacilityBigTypeAllList({ type: 'facl' }).then(res => {
        if (res.code === 200) {
          // 中转站/收集站
          let transitIndex = res.result.findIndex(item => {
            return item.name == '中转站'
          })
          let gatherIndex = res.result.findIndex(item => {
            return item.name == '收集站'
          })
          // let faclIdArr = [res.result[transitIndex].id, res.result[gatherIndex].id]
          let faclIdArr = [res.result[transitIndex].id]
          this.$http.getFacilityAllList({ id: faclIdArr.join() }).then(resItem => {
            if (resItem.code === 200) {
              this.gatherTreeArr = resItem.result.content.list
            }
          })

          // 处理站
          let disposeIndex = res.result.findIndex(item => {
            return item.name == '处理站'
          })
          this.$http.getFacilityAllList({ id: res.result[disposeIndex].id }).then(resItem => {
            if (resItem.code === 200) {
              this.disposeTreeArr = resItem.result.content.list
            }
          })
        }
      })
    },
    // 选择网格
    onChangeGridName (section) {
      if (section.type != 'depart') {
        this.gridName = section.name
        this.gridNameId = section.id
      }
    },
    // 选择车辆
    onChangeCar (section) {
      if (section.type == 'car') {
        this.carId = section.id
        this.car = section.name
        this.driverId = section.bindaccount
        this.driver = section.bindaccountname
        this.getSevenMileage()
      }
    },
    // 获取近七天里程
    getSevenMileage () {
      let params = {
        carIds: this.carId.split(','),
        startDate: this.nearlySevenDay[0],
        endDate: this.nearlySevenDay[1]
      }
      this.$http.getGarbageTransitSevenMileage(params).then(res => {
        if (res.code === 200) {
          this.mileage = res.result
        }
      })
    },
    // 获取作业班组列表
    getGroupList () {
      let params = {
        module: 5
      }
      this.$http.getWorkGroupItemAllList(params).then(res => {
        if (res.code === 200) {
          this.groupTreeArr = res.result
          this.groupTreeArr.map(item => {
            item.name = item.depName
            item.id = item.depid
            item.type = 'depart'
            if (item.children) {
              item.children.map(subItem => {
                subItem.type = 'group'
                return subItem
              })
            }
            return item
          })
        }
      })
    },
    // 选择班组
    onChangeGroup (section) {
      if (section.type == 'group') {
        this.groupId = section.workGroupId
        this.group = section.name
      }
    },
    // 获取作业规则列表
    getRuleList () {
      let params = {
        page: 1,
        size: 999,
        module: 5
      }
      this.$http.getWorkRuleList(params).then(res => {
        if (res.code === 200) {
          this.ruleArr = res.result.workRules
        }
      })
    },
    // 作业规则回调
    onChangeRule (data) {
      if (data) {
        this.rule = data.label
        this.ruleId = data.value
      }
    },
    // 选择开始日期
    onChangeStart (value) {
      this.startTime = value
    },
    // 选择结束日期
    onChangeEnd (value) {
      this.endTime = value
    },
    // 选择中转/收集站
    onChangeGather (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'facl') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.gatherId = idArr.join()
      this.gather = valueArr.join()
    },
    // 选择处理站
    onChangeDispose (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'facl') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.disposeId = idArr.join()
      this.dispose = valueArr.join()
    },
    // 点击确认
    onClickConfirm () {
      if (!this.plan) return this.$Message.info('请输入计划名称')
      // if (!this.gridName) return this.$Message.info('请选择网格')
      if (!this.car) return this.$Message.info('请选择作业车辆')
      if (!this.group) return this.$Message.info('请选择作业班组')
      if (!this.startTime) return this.$Message.info('请选择有效时段')
      if (!this.endTime) return this.$Message.info('请选择有效时段')
      if (this.startTime > this.endTime) return this.$Message.info('请选择正确的有效时段')
      this.loading = true
      if (!this.dataId) {
        let repeatName = {
          planName: this.plan,
          gridIdList: [this.gridNameId],
          module: 2,
          type: 3
        }
        this.$http.repeatCarPlanName(repeatName).then((res) => {
          if (res.result.length) {
            this.loading = false
            this.$Message.info({
              content: '作业计划名称重复'
            })
          } else {
            // 创建
            this.createPlan()
          }
        })
      } else {
        this.createPlan()
      }
    },
    createPlan () {
      let params = {
        plan: this.plan,
        ruleId: this.ruleId,
        carId: this.carId.split(','),
        groupId: this.groupId,
        startTime: this.startTime,
        endTime: this.endTime,
        remark: this.remark,
        departmentId: this.departmentId,
        expectMileage: this.expectMileage,
        period: 1,
        status: 1,
        type: 3,
      }
      if (this.gatherId && this.gatherId.length > 0) {
        params.gatherId = this.gatherId.split(',')
      } else {
        params.gatherId = []
      }
      if (this.disposeId && this.disposeId.length > 0) {
        params.disposeId = this.disposeId.split(',')
      } else {
        params.disposeId = []
      }
      if (this.dataId) {
        params.carPlanIdList = [this.dataId]
        // params.gridNameId = this.gridNameId
        params.flag = 2
      } else {
        // params.gridNameIdList = [this.gridNameId]
        params.module = 2
      }
      this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.plan = ''
      this.gridName = ''
      this.gridNameId = ''
      this.car = ''
      this.carId = ''
      this.driver = ''
      this.driverId = ''
      this.group = ''
      this.groupId = ''
      this.rule = ''
      this.ruleId = ''
      this.gather = ''
      this.gatherId = ''
      this.dispose = ''
      this.disposeId = ''
      this.remark = ''
      this.departmentId = ''
      this.departmentName = ''
      this.expectMileage = ''
      this.timeInit()
      this.startDateDisabled = false
      this.endDateDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-cont {
  overflow-y: visible;
  .form-ul {
    .form-ul-li {
      .li-title {
        min-width: 110px;
      }
    }
  }
}
</style>
