<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex===0">
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteCar"
                      filterable
                      clearable
                      placeholder="请输入车牌号"
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.id"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <Input v-model="planName"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     placeholder="计划名称"
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===1">
              <Select v-model="teamGroupType"
                      placeholder="班组类型"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in teamGroupTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Input v-model="teamGroupName"
                     maxlength="30"
                     v-stringLimit
                     placeholder="班组名称"
                     clearable
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===2">
              <Input v-model="ruleName"
                     maxlength="30"
                     v-stringLimit
                     placeholder="规则名称"
                     clearable
                     class="m-r-10"
                     style="width:160px" />
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/garbageTransit-crewScheduling/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/garbageTransit-crewScheduling/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template v-if="currentTabIndex===0">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/garbageTransit-crewScheduling/add'])"
                    @click.stop="garbageTransitPlanVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加计划
            </Button>
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/garbageTransit-crewScheduling/add'])"
                    @click.stop="gridVisible=true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加网格
            </Button>
            <!-- <Button type="primary"
                    class="m-r-10"
                    @click.stop="garbageTransitPlanBatchAddVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              批量添加计划
            </Button> -->
            <Dropdown v-if="checkPermi(['/admin/garbageTransit-crewScheduling/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="part">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('part')">
                    <Button>
                      导出部分
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="now">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('now')">
                    <Button>
                      导出本页
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="all">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('all')">
                    <Button>
                      导出全部
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <ExportTemplate :path="getExportTemplatePath"
                            :data={type:3}
                            v-if="checkPermi(['/admin/garbageTransit-crewScheduling/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出模板
              </Button>
            </ExportTemplate>
            <ReaderFile @uploadSuccess="onClickReader"
                        accept=".xls,.xlsx"
                        :action='getReaderFileAction'
                        v-if="checkPermi(['/admin/garbageTransit-crewScheduling/import'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiazai"
                      size="16"></Icon>
                导入
              </Button>
            </ReaderFile>
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/garbageTransit-crewScheduling/edit','/admin/garbageTransit-crewScheduling/delete'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="edit"
                              v-if="checkPermi(['/admin/garbageTransit-crewScheduling/edit'])">批量修改</DropdownItem>
                <!-- <DropdownItem name="disable">批量启用</DropdownItem> -->
                <!-- <DropdownItem name="scrap">批量禁用</DropdownItem> -->
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/garbageTransit-crewScheduling/delete'])">批量删除</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </template>
        <template v-if="currentTabIndex===1">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/garbageTransit-crewScheduling/add'])"
                    @click.stop="workGroupVisible=true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
        <template v-if="currentTabIndex===2">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/garbageTransit-crewScheduling/add'])"
                    @click.stop="workRuleVisible=true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
        <template v-if="currentTabIndex===1">
          <div class="flex a-center">
            <Icon custom="i-icon icon-tishi1"
                  size="16"
                  color="#FF9B77"></Icon>
            当前平台设置为{{summerTime}}至{{winterTime}}为夏季作息时间，{{winterTime}}为冬季作息时间
            <Button type="text"
                    class="m-l-5"
                    @click.stop="onClickTime">设置冬夏时间 >></Button>
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}"
             v-if="currentTabIndex===0">
          <MyTree :data="departmentTreeArr"
                  style="padding:0 10px"
                  @on-select-change="onSelectChangeDepart"></MyTree>
        </div>
        <div class="table">
          <template v-if="currentTabIndex===0">
            <Table ref="table"
                   :width="tableWidth"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/garbageTransit-crewScheduling/edit']"
                        @click.stop="onClickEditPlan(row)">修改</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/garbageTransit-crewScheduling/delete']"
                        @click.stop="onClickDeletePlan(row)">删除</Button>
              </template>
            </Table>
          </template>
          <template v-else>
            <Table ref="table"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===1">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/garbageTransit-crewScheduling/edit']"
                          @click.stop="onClickEditGroup(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/garbageTransit-crewScheduling/delete']"
                          @click.stop="onClickDeleteGroup(row)">删除</Button>
                </template>
                <template v-if="currentTabIndex===2">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/garbageTransit-crewScheduling/edit']"
                          @click.stop="onClickEditRule(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/garbageTransit-crewScheduling/edit']"
                          @click.stop="onClickStatusRule(row)">{{row.isEnable==1?'禁用':'恢复'}}</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/garbageTransit-crewScheduling/delete']"
                          @click.stop="onClickDeleteRule(row)">删除</Button>
                </template>
              </template>
            </Table>
          </template>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>

    <!-- 新增 / 修改 排班计划 -->
    <GarbageTransitPlanModal v-model="garbageTransitPlanVisible"
                             :dataId.sync="garbageTransitPlanId"
                             @onClickConfirm="getList"
                             @onChange='onChangClearRowStyle'></GarbageTransitPlanModal>

    <!-- 批量 新增 排班计划 -->
    <GarbageTransitPlanBatchAddModal v-model="garbageTransitPlanBatchAddVisible"
                                     :dataId.sync="garbageTransitPlanBatchAddId"
                                     @onClickConfirm="getList"></GarbageTransitPlanBatchAddModal>

    <!-- 批量 修改 排班计划 -->
    <GarbageTransitPlanBatchModal v-model="garbageTransitPlanBatchVisible"
                                  :dataId.sync="garbageTransitPlanBatchId"
                                  @onClickConfirm="getList"></GarbageTransitPlanBatchModal>

    <!-- 新增 / 修改作业班组 -->
    <WorkGroupModal v-model="workGroupVisible"
                    :dataId.sync="workGroupId"
                    :ruleType="5"
                    @onClickConfirm="getList"
                    @onChange='onChangClearRowStyle'></WorkGroupModal>

    <!-- 作业规则 -->
    <WorkRuleModal v-model="workRuleVisible"
                   :dataId.sync="workRuleId"
                   type="5"
                   @onClickConfirm="getList"
                   @onChange='onChangClearRowStyle'></WorkRuleModal>

    <!-- 新增 / 修改网格 -->
    <GridModal v-model="gridVisible"
               :width="drawerWidth"></GridModal>

    <!-- 设置冬夏时间 -->
    <WorkTimeModal v-model="workTimeVisible"
                   :dataId.sync="workTimeId"
                   @onClickConfirm="getTimeDetail"></WorkTimeModal>

    <!-- 表格中的绑定规则 -->
    <TableWorkRuleModal v-model="tableWorkRuleVisible"
                        :data.sync="tableWorkRuleData"
                        type="5"
                        @onChange='onChangClearRowStyle'></TableWorkRuleModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MyTree from '@/components/common/MyTree';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import GarbageTransitPlanModal from '@/components/product/admin/modal/work/GarbageTransitPlanModal'
import GarbageTransitPlanBatchModal from '@/components/product/admin/modal/work/GarbageTransitPlanBatchModal'
import GarbageTransitPlanBatchAddModal from '@/components/product/admin/modal/work/GarbageTransitPlanBatchAddModal'
import WorkGroupModal from '@/components/product/admin/modal/work/WorkGroupModal'
import WorkRuleModal from '@/components/product/admin/modal/work/WorkRuleModal'
import WorkTimeModal from '@/components/product/admin/modal/work/WorkTimeModal'
import GridModal from '@/components/product/admin/modal/work/GridModal'
import TableWorkRuleModal from '@/components/product/admin/modal/work/TableWorkRuleModal'
import ExportTemplate from '@/components/common/ExportTemplate'
import ReaderFile from '@/components/common/ReaderFile'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    GarbageTransitPlanModal,
    GarbageTransitPlanBatchModal,
    GarbageTransitPlanBatchAddModal,
    WorkGroupModal,
    WorkRuleModal,
    WorkTimeModal,
    GridModal,
    MyTree,
    MyTreeSelect,
    TableWorkRuleModal,
    ExportTemplate,
    ReaderFile
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '排班计划'
        },
        {
          name: '作业班组'
        },
        {
          name: '作业规则'
        },
      ],
      carNumber: '',
      carNumberId: '',
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      planName: '',
      name: '',
      teamGroupType: -1,
      teamGroupTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '固定班'
        },
        {
          id: 2,
          name: '冬夏班'
        },
      ],
      teamGroupName: '',
      ruleName: '',
      date: '',
      departId: '',
      departmentName: '',
      departmentId: '',
      summerTime: '',
      winterTime: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsPlan: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '计划名称',
          key: 'planName',
          minWidth: 180,
          tooltip: true,
          align: 'center',
          fixed: 'left'
        },
        {
          title: '车牌号',
          key: 'carNumbers',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '网格名称',
        //   key: 'gridName',
        //   width: 90,
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '机构名称',
          key: 'departmentName',
          tooltip: true,
          width: 160,
          align: 'center'
        },
        {
          title: '预计里程(km)',
          key: 'expectMileage',
          tooltip: true,
          width: 160,
          align: 'center'
        },
        {
          title: '中转站',
          key: 'collectStandNames',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '处理站',
          key: 'processStandNames',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '有效期',
          width: 170,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', [
              h('span', row.workStartDate + '~' + row.workEndDate)
            ]);
          }
        },
        {
          title: '车辆作业班组',
          key: 'workGroupName',
          width: 100,
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业规则',
          key: 'workRuleName',
          width: 70,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindRule(row)
                }
              }
            }, row.workRuleName ? row.workRuleName : '点击绑定');
          }
        },
        {
          title: '备注',
          key: 'remark',
          width: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 120,
          align: 'center'
        }
      ],
      columnsTeamGroup: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '班组名称',
          key: 'name',
          width: 120,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          width: 180,
          tooltip: true,
          align: 'center'
        },
        {
          title: '班组类型',
          key: 'kaoqinType',
          width: 100,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', [
              h('span', row.kaoqinType == 1 ? '固定班' : '冬夏班')
            ]);
          }
        },
        {
          title: '作业时间段',
          key: 'managePhone',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let str = ''
            if (row.kaoqins) {
              row.kaoqins.forEach((item, index) => {
                str = str + item.name + ' '
                if (item.sbsj && item.xbsj) {
                  str = str + '【' + item.sbsj + '~' + item.xbsj + '】'
                } else {
                  str = str + '【夏' + item.xsbsj + '~' + item.xxbsj + '】 【冬' + item.dsbsj + '~' + item.dxbsj + '】'
                }
                if (index == row.kaoqins.length - 1) {
                  str = str
                } else {
                  str = str + '，'
                }
              })
            }
            return h('span', str)
          }
        },
        {
          title: '备注',
          key: 'remark',
          width: 260,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 120,
          align: 'center'
        }
      ],
      columnsRule: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '规则名称',
          key: 'ruleName',
          tooltip: true,
          width: 160,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '启用状态',
          key: 'isEnable',
          tooltip: true,
          width: 160,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.isEnable == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '规则详情',
          tooltip: true,
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let str = ''
            if (row.ruleItems) {
              let arr = row.ruleItems.map((item) => {
                let itemArr = []
                if (item.isEnable == 1) {
                  for (const i in item.content) {
                    if (i === 'scope') {
                      itemArr.push('范围' + item.content.scope + '米')
                    }
                    if (i === 'time') {
                      itemArr.push('时间' + item.content.time + '分钟')
                    }
                    if (i === 'yxc') {
                      itemArr.push('允许出' + item.content.yxc + '次')
                    }
                    if (i === 'dczc') {
                      itemArr.push('单次最长' + item.content.dczc + '分钟')
                    }
                    if (i === 'cwgpd') {
                      itemArr.push('出网格判定' + item.content.cwgpd + '分钟')
                    }
                    if (i === 'spwld') {
                      itemArr.push('扫盘未落地' + item.content.spwld + '分钟')
                    }
                    if (i === 'ptwkq') {
                      itemArr.push('喷头未开启' + item.content.ptwkq + '分钟')
                    }
                    if (i === 'tl') {
                      itemArr.push('停留' + item.content.tl + '分钟')
                    }
                    if (i === 'wdd') {
                      itemArr.push('未达到' + item.content.wdd + '%')
                    }
                    if (i === 'zx') {
                      itemArr.push('最小' + item.content.zx + 'km/h')
                    }
                    if (i === 'zd') {
                      itemArr.push('最大' + item.content.zd + 'km/h')
                    }
                    if (i === 'ccl') {
                      itemArr.push('超出量' + item.content.ccl + '%')
                    }
                    if (i === 'wd') {
                      itemArr.push('晚到' + item.content.wd + '分钟')
                    }
                  }
                }
                return itemArr.join()
              })
              arr = arr.filter(item => item)
              str = arr.join()
            }
            return h('span', str)
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          width: 160,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 新增 / 修改 排班计划
      garbageTransitPlanVisible: false,
      garbageTransitPlanId: '',
      // 批量 新增 排班计划
      garbageTransitPlanBatchAddVisible: false,
      garbageTransitPlanBatchAddId: '',
      // 批量 修改 排班计划
      garbageTransitPlanBatchVisible: false,
      garbageTransitPlanBatchId: '',
      // 新增 / 修改 作业班组
      workGroupVisible: false,
      workGroupId: '',
      // 作业规则
      workRuleVisible: false,
      workRuleId: '',
      // 新增 / 修改 网格
      gridVisible: false,
      gridId: '',
      // 设置冬夏时间
      workTimeVisible: false,
      workTimeId: null,
      drawerWidth: 0,
      // 表格中的绑定规则
      tableWorkRuleVisible: false,
      tableWorkRuleData: {},
      drawerWidth: 0
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.id == items.id
            })
            this.selectVisitedIndexArr = [data.id]
          }
          if (this.currentTabIndex === 1) {
            let data = this.data.find((items, index) => {
              return item.workGroupId == items.workGroupId
            })
            this.selectVisitedIndexArr = [data.workGroupId]
          }
          if (this.currentTabIndex === 2) {
            let data = this.data.find((items, index) => {
              return item.workRuleId == items.workRuleId
            })
            this.selectVisitedIndexArr = [data.workRuleId]
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'carTreeArr': 'getCarTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsPlan
          break;
        case 1:
          columns = this.columnsTeamGroup
          break;
        case 2:
          columns = this.columnsRule
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportCarPlan
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportCarPlanTem
          break;
        default:
          break;
      }
      return path
    },
    getReaderFileAction () {
      let readerFileAction = []
      switch (this.currentTabIndex) {
        case 0:
          readerFileAction = '/hss/ljzyworkplan/ljzyPlanExcelImport'
          break;
        default:
          break;
      }
      return readerFileAction
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateCarTreeList',
      'updateDepartmentTreeList'
    ]),
    init () {
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 350
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.updateCarTreeList()
      this.updateDepartmentTreeList()
      this.getList()
      this.getTimeDetail()
    },
    // 选择部门
    onSelectChangeDepart (section, data) {
      this.departId = data.id
      this.onClickSearch()
    },
    // 选择车牌号
    onChangeCarNumber (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // 获取冬夏时间详情
    getTimeDetail () {
      this.$store.dispatch('getTimeCompleteDetail', 5).then(res => {
        let summer = res.summerDate.split('-')
        this.summerTime = summer[0] + '月' + summer[1] + '日'
        let winter = res.winterDate.split('-')
        this.winterTime = winter[0] + '月' + winter[1] + '日'
      })
    },
    // 点击设置冬夏时间
    onClickTime () {
      this.workTimeVisible = true
      this.workTimeId = 5
    },
    // 绑定规则
    onClickBindRule (row) {
      this.selectVisitedArr.push(row)
      this.tableWorkRuleVisible = true
      this.tableWorkRuleData = row
    },
    // 批量操作下拉菜单
    onClickBatch (name) {
      if (this.selectArr.length == 0) return this.$Message.info('请选择数据')
      switch (name) {
        case 'delete':
          this.onDeletePlan(this.selectArr)
          break;
        // 禁用 
        case 'disable':
          this.$Modal.confirm({
            title: '禁用',
            content: '确认禁用?',
            onOk: () => {
              let params = {
                ids: this.selectArr.join(),
                status: 0
              }
              this.$store.dispatch('createOrEditCar', params).then(res => {
                this.getList()
              }).catch(err => {
                this.$Message.info(err.message)
              })
            }
          })
          break;
        // 启用
        case 'recover':
          this.$Modal.confirm({
            title: '启用',
            content: '确认启用?',
            onOk: () => {
              let params = {
                ids: this.selectArr.join(),
                status: 1
              }
              this.$store.dispatch('createOrEditCar', params).then(res => {
                this.getList()
              }).catch(err => {
                this.$Message.info(err.message)
              })
            }
          })
          break;
        case 'edit':
          this.garbageTransitPlanBatchVisible = true
          this.garbageTransitPlanBatchId = this.selectArr.join()
          break;
        default:
          break;
      }
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    onChangeSelect (selection) {
      this.selectDataArr = selection
      switch (this.currentTabIndex) {
        case 0:
          this.selectArr = selection.map(item => item.id)
          break;
        case 1:
          this.selectArr = selection.map(item => item.workGroupId)
          break;
        case 2:
          this.selectArr = selection.map(item => item.workRuleId)
          break;
        default:
          break;
      }
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.currentTabIndex === 0) {
        if (this.selectVisitedIndexArr.includes(row.id)) {
          return 'table-visited-bgcolor'
        }
      }
      if (this.currentTabIndex === 1) {
        if (this.selectVisitedIndexArr.includes(row.workGroupId)) {
          return 'table-visited-bgcolor'
        }
      }
      if (this.currentTabIndex === 2) {
        if (this.selectVisitedIndexArr.includes(row.workRuleId)) {
          return 'table-visited-bgcolor'
        }
      } if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getPlanList()
          break;
        case 1:
          this.getGroupList()
          break;
        case 2:
          this.getRuleList()
          break;
        default:
          break;
      }
    },
    // 获取排班计划列表
    getPlanList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        planName: this.planName,
        departmentId: this.departmentId,
      }
      if (this.remoteCar) {
        params.carIds = this.remoteCar.split(',')
      }
      this.tableLoading = true
      this.$http.getGarbageTransitPlanList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改排班计划
    onClickEditPlan (row) {
      this.selectVisitedArr.push(row)
      this.garbageTransitPlanVisible = true
      this.garbageTransitPlanId = row.id
    },
    // 删除排班计划
    onClickDeletePlan (row) {
      this.selectVisitedArr.push(row)
      this.onDeletePlan([row.id])
    },
    onDeletePlan (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            carPlanIdList: arr,
            type: 3
          }
          this.$http.deleteCarPlan(params).then(res => {
            if (res.code === 200) {
              this.onChangClearRowStyle()
              if (res.result.message) {
                this.$Message.info(res.result.message)
              } else {
                this.$Message.info(res.message)
                this.getList()
              }
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改排班计划状态 未启用
    onClickStatusPlan (row) {
      if (row.isEnable == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              ljqyWorkPlanId: row.ljqyWorkPlanId,
              status: 0
            }
            this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
              this.getList()
              // this.$store.dispatch('updateCarTypeAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '启用',
          content: '确认启用?',
          onOk: () => {
            let params = {
              ljqyWorkPlanId: row.ljqyWorkPlanId,
              status: 1
            }
            this.$store.dispatch('createOrEditMechanicalCleanPlan', params).then(res => {
              this.getList()
              // this.$store.dispatch('updateCarTypeAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    // 获取班组列表
    getGroupList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        kaoqinType: this.teamGroupType == 0 ? '' : this.teamGroupType,
        name: this.teamGroupName,
        // depid: "",
        module: 5
      }
      this.tableLoading = true
      this.$http.getWorkGroupList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.workGroups
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改班组
    onClickEditGroup (row) {
      this.selectVisitedArr.push(row)
      this.workGroupVisible = true
      this.workGroupId = row.workGroupId
    },
    // 删除班组
    onClickDeleteGroup (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            workGroupIdList: [row.workGroupId],
            module: 5
          }
          this.$http.deleteWorkGroupCar(params).then(res => {
            if (res.code === 200) {
              this.onChangClearRowStyle()
              if (res.result.workPlanIdList.length) {
                this.$Message.info({
                  content: '此班组已进行排班，删除会导致数据错误',
                  duration: 3
                })
              } else {
                this.getList()
                this.$Message.info({
                  content: res.message,
                  duration: 3
                })
              }
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 获取作业规则列表
    getRuleList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        name: this.ruleName,
        module: 5
      }
      this.tableLoading = true
      this.$http.getWorkRuleList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.workRules
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改作业规则
    onClickEditRule (row) {
      this.selectVisitedArr.push(row)
      this.workRuleVisible = true
      this.workRuleId = row.workRuleId
    },
    // 修改作业规则状态
    onClickStatusRule (row) {
      this.selectVisitedArr.push(row)
      if (row.isEnable == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.workRuleId,
              status: 0
            }
            this.$store.dispatch('createOrEditWorkRule', params).then(res => {
              this.getList()
              // this.$store.dispatch('updateCarTypeAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '启用',
          content: '确认启用?',
          onOk: () => {
            let params = {
              id: row.workRuleId,
              status: 1
            }
            this.$store.dispatch('createOrEditWorkRule', params).then(res => {
              this.getList()
              // this.$store.dispatch('updateCarTypeAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 删除作业规则
    onClickDeleteRule (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            workRuleIdList: [row.workRuleId],
            module: 5
          }
          this.$http.deleteWorkRuleCar(params).then(res => {
            if (res.code === 200) {
              if (res.result.workPlanIdList.length) {
                this.$Message.info({
                  content: '此规则已进行排班，删除会导致数据错误',
                  duration: 3
                })
              } else {
                this.getList()
                this.$Message.info({
                  content: res.message,
                  duration: 3
                })
              }
            }
          })
          this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = []
          }
        })
      } else {
        this.remoteCarList = []
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    // 重置搜索条件
    resetSearchData () {
      // 计划
      this.remoteCar = ''
      this.planName = ''
      this.carNumber = ''
      this.carNumberId = ''
      this.departId = ''
      this.departmentId = ''
      this.departmentName = ''
      // 班组
      this.teamGroupType = ''
      this.teamGroupName = ''
      // 规则
      this.ruleName = ''
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            planName: this.planName,
            departmentId: this.departId,
            type: 3
          }
          if (this.carNumberId) {
            data.carIds = this.carNumberId.split(',')
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>